import './src/scss/theme.scss'
import('../../src/js/app')
require('owl.carousel')

$(document).ready(function () {
  $('.suggestion-slider .owl-carousel').owlCarousel({
    margin: 30,
    dots: true,
    nav: true,
    pagination: true,
    paginationNumbers: true,
    loop: true,
    navigation: true,
    navClass: ['prev', 'next'],
    items: 1,
    autoplay: true,
    autoplayHoverPause: true,
    navText: ["<i class='fa fa-chevron-left d-flex'></i>", "<i class='fa fa-chevron-right d-flex'></i>"]
  })
})

document.querySelectorAll('.highlight-card').forEach(card => {
  card.addEventListener('click', function () {
    this.classList.toggle('clicked')
  })

  document.addEventListener('click', (event) => {
    if (!card.contains(event.target)) {
      card.classList.remove('clicked')
    }
  })
})
